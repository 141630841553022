<script>
import { mapGetters, mapState } from "vuex";
import MainSkeletonBox from "@/components/helpers/MainSkeletonBox.vue";

export default {
  components: { MainSkeletonBox },

  props: {
    card: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      analyticsTypes: (state) => state.analytics.analyticsTypes,
      statusAnalyticsTypes: (state) => state.analytics.statusAnalyticsTypes,
    }),

    ...mapGetters({
      getAnalyticsTypeAmountById: "analytics/getAnalyticsTypeAmountById",
    }),
  },
};
</script>

<template>
  <div
    class="analytics-card"
    :class="{ 'analytics-card_selected': selected }"
    @click="$emit('select')"
  >
    <div class="analytics-card__icon-wrapper">
      <span
        class="analytics-card__icon"
        :class="`icon-analytics-${card.icon}`"
      />
    </div>

    <p class="analytics-card__name" v-html="card.name" />

    <p
      v-if="statusAnalyticsTypes === 'SUCCESS'"
      class="analytics-card__amount"
      :class="{
        'analytics-card__amount_small':
          getAnalyticsTypeAmountById(card.id).length > 9,
      }"
    >
      {{ getAnalyticsTypeAmountById(card.id) }} RC
    </p>

    <MainSkeletonBox
      v-if="statusAnalyticsTypes === 'LOADING'"
      style="width: 100%; height: 22px; border-radius: 8px"
    />
  </div>
</template>

<style lang="scss" scoped>
.analytics-card {
  padding: 16px;
  border-radius: 12px;
  background-color: $background-white;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &__icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-second;
    margin-bottom: 16px;
  }

  &__icon {
    width: 32px;
    height: 32px;
    background-color: $dark-primary;
  }

  &__name {
    @include text-2;
    color: $dark-third;
    margin-bottom: 4px;
    margin-top: auto;
  }

  &__amount {
    @include body-1-bold;
    color: $dark-primary;

    &_small {
      @include text-2-bold;
    }
  }

  &_selected {
    border: 1px solid $gold-dark;
  }
}
</style>
