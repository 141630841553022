export const ANALYTICS_COLORS = [
  "#FF4C4C",
  "#2B5AFF",
  "#4DCA00",
  "#A12CFF",
  "#FF9920",
  "#FF49CC",
  "#00C9C9",
  "#686868",
];
