import { parse, format, isMatch } from "date-fns";
import { MONTHS } from "@/package/const/months";

export default function formatXAxisDate(date, isTooltip = false) {
  if (date) {
    if (isMatch(date, "yyyy-MM")) {
      if (isTooltip) {
        const month = format(parse(date, "yyyy-MM", new Date()), "MM");
        const year = format(parse(date, "yyyy-MM", new Date()), "yyyy");

        return `${MONTHS.find((el) => el.value === month).name} ${year}`;
      }

      return format(parse(date, "yyyy-MM", new Date()), "MM.yyyy");
    } else if (isMatch(date, "yyyy-MM-dd")) {
      return format(new Date(date), "dd.MM.yy");
    } else if (isMatch(date, "yyyy-MM-dd HH")) {
      return format(
        parse(date, "yyyy-MM-dd HH", new Date()),
        "dd.MM.yyyy HH:mm"
      );
    } else if (isMatch(date, "yyyy")) {
      return date;
    } else if (date.includes(" – ") && date.split("–").length > 0) {
      const date1 = date.split("–")[0].replace(/\s/g, "");
      const date2 = date.split("–")[1].replace(/\s/g, "");

      return `${format(new Date(date1), "dd.MM.yy")} -
        ${format(new Date(date2), "dd.MM.yy")}`;
    } else {
      return date;
    }
  }
}
