var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analytics-chart-legend-item"},[_c('div',{staticClass:"analytics-chart-legend-item__title"},[_c('span',{style:({ backgroundColor: _vm.item.color })}),_c('p',[_vm._v(_vm._s(_vm.item.name))]),(_vm.item.tooltip)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.item.tooltip,
        show: _vm.isShowTooltip,
        trigger: 'manual',
        placement: 'auto',
        offset: 4,
      }),expression:"{\n        content: item.tooltip,\n        show: isShowTooltip,\n        trigger: 'manual',\n        placement: 'auto',\n        offset: 4,\n      }"}]},[_c('span',{staticClass:"icon-info",on:{"mouseenter":function($event){return _vm.showTooltip(false)},"mouseleave":_vm.closeTooltip,"click":function($event){return _vm.showTooltip(true)}}})]):_vm._e()]),_c('div',{staticClass:"analytics-chart-legend-item__value"},[_c('span',{staticClass:"icon-analytics-pay"}),_c('p',[_vm._v(_vm._s(_vm.item.value)+" RC")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }