export const ANALYTICS_CARDS = [
  {
    id: "total",
    icon: "pay",
    name: "Суммарный доход",
  },
  {
    id: "marketing",
    icon: "money",
    name: "Маркетинг-план",
    color: "#FF6666",
  },
  {
    id: "gift",
    icon: "gift",
    name: "Подарочный счет",
    color: "#5BAF54",
  },
  {
    id: "rc_market_income",
    icon: "shop",
    name: "Доход<br /> по RC Market",
    color: "#FFDA7A",
  },
  {
    id: "rc_city_income",
    icon: "tsp",
    name: "Доход<br /> по RC City",
    color: "#2F80ED",
  },
  {
    id: "online_selling",
    icon: "cashback",
    name: "Онлайн-покупки",
    color: "#FFBCD0",
  },
];
