<script>
import formatXAxisDate from "@/package/usecases/format-x-axis-date";
import removeTrailingZeros from "@/package/helpers/format-number.helpers";
import { ANALYTICS_COLORS } from "@/package/const/analytics-colors";
import MainChart from "@/components/helpers/MainChart.vue";

export default {
  name: "AnalyticsLineChart",

  components: { MainChart },

  props: {
    category: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      updatingChart: false,

      options: {
        color: ANALYTICS_COLORS,
        tooltip: {
          trigger: "axis",
          padding: [16, 16, 8, 16],
          width: 300,
          formatter: function (params) {
            const colorSpan = (color) =>
              '<span style="display:inline-block; margin-right:8px; border-radius:10px; min-width:8px; height:8px; background-color:' +
              color +
              '"></span>';

            let rez =
              "<p style='font-size: 16px; color: #212121; margin-bottom: 16px;'>" +
              formatXAxisDate(params[0].axisValue, true) +
              "</p>";

            params.forEach((item) => {
              const xx =
                "<div style='display: flex; align-items: center; margin-bottom: 8px;'>" +
                colorSpan(item.color) +
                `<p style="color: #666;">${item.seriesName}</p>` +
                `<p style='color: #212121; font-weight: 600; margin-left: 32px; text-align: end; width: 100%;'>
                  ${removeTrailingZeros(item.data, 2)} RC
                </p></div>`;

              rez += xx;
            });

            return rez;
          },
          textStyle: {
            fontFamily: "Montserrat",
            fontSize: 14,
          },
        },
        legend: {
          bottom: "-8",
          left: "0",
          textStyle: {
            fontFamily: "Montserrat",
            fontSize: "14",
          },
          itemGap: 16,
          icon: "circle",
          itemWidth: 8,
          padding: 8,
        },
        grid: {
          left: "0",
          right: "4%",
          top: "8",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 12,
            color: "#BDBDBD",
            formatter: function (value) {
              return formatXAxisDate(value);
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: 12,
            color: "#BDBDBD",
            formatter: function (value) {
              return `${value} RC`;
            },
          },
        },
        series: [],
      },
    };
  },

  watch: {
    category: {
      deep: true,
      handler() {
        this.setChartOptions();
      },
    },
  },

  methods: {
    setChartOptions() {
      let xAxis = [];
      let series = [];

      if (this.data) {
        xAxis = this.data[this.category.id].xAxis.data;
        this.data[this.category.id].series.forEach((item) => {
          const sum = item.data.reduce((sum, el) => {
            return sum + el;
          }, 0);

          if (sum > 0) {
            series.push({
              name: item.name,
              type: "line",
              data: item.data,
            });
          }
        });
      }

      this.updatingChart = true;

      this.options.xAxis.data = xAxis;
      this.options.series = series;

      setTimeout(() => {
        this.updatingChart = false;
      }, 100);
    },
  },

  mounted() {
    this.setChartOptions();
  },
};
</script>

<template>
  <div class="line-chart">
    <transition name="fade">
      <MainChart
        v-if="!updatingChart"
        class="line-chart__chart"
        :options="options"
      />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.line-chart {
  height: 400px;

  &__chart {
    min-width: calc(756px + 64px);
    padding: 32px;
  }
}

@media (max-width: 1279px) {
  .line-chart {
    height: 480px;
  }
}

@media (max-width: 1023px) {
  .line-chart {
    &__chart {
      min-width: calc(756px + 48px);
      padding: 24px;
    }
  }
}
</style>
