<script>
import { mapState } from "vuex";
import { ANALYTICS_CARDS } from "@/package/const/analytics-cards";
import { ANALYTICS_COLORS } from "@/package/const/analytics-colors";
import removeTags from "@/package/helpers/remove-tags";
import setAnalyticsLegendTooltip from "@/package/helpers/set-analytics-legend-tooltip";
import removeTrailingZeros from "@/package/helpers/format-number.helpers";
import AnalyticsChartLegendItem from "@/components/analytics/AnalyticsChartLegendItem.vue";

export default {
  name: "AnalyticsChartLegend",

  components: { AnalyticsChartLegendItem },

  props: {
    category: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      ANALYTICS_CARDS,
      legend: [],
    };
  },

  watch: {
    category: {
      deep: true,
      handler() {
        this.setLegendByCategory(this.category.id);
      },
    },
  },

  computed: {
    ...mapState({
      innerWidth: (state) => state.innerWidth,
    }),

    title() {
      return removeTags(this.category.name);
    },
  },

  methods: {
    setLegendByCategory(categoryId) {
      this.legend = [];
      let index = 0;

      this.data[categoryId].series.forEach((item) => {
        const value = item.data.reduce((sum, el) => {
          return sum + el;
        }, 0);

        if (value > 0) {
          this.legend.push({
            name: item.name,
            value: removeTrailingZeros(value, 2),
            color: ANALYTICS_COLORS[index],
            tooltip: setAnalyticsLegendTooltip(item.name),
          });

          index++;
        }
      });
    },
  },

  beforeMount() {
    this.setLegendByCategory(this.category.id);
  },
};
</script>

<template>
  <div class="analytics-chart-legend">
    <h3>{{ title }}</h3>

    <div class="analytics-chart-legend__list">
      <AnalyticsChartLegendItem
        v-for="(item, index) in legend"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.analytics-chart-legend {
  > h3 {
    @include body-1-bold();
    color: $dark-primary;
  }

  &__list {
    margin: 24px 0 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 24px;

    @media (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 16px;
    }
  }
}
</style>
