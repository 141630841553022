<script>
export default {
  name: "AnalyticsChartLegendItem",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isShowTooltip: false,
      tooltipTimeout: undefined,
    };
  },

  methods: {
    showTooltip(isClick = false) {
      if (this.innerWidth < 767) {
        this.isShowTooltip = true;

        setTimeout(() => {
          this.isShowTooltip = false;
        }, 4000);
      } else {
        if (isClick) {
          this.isShowTooltip = true;
          return;
        }

        this.tooltipTimeout = setTimeout(() => {
          this.isShowTooltip = true;
        }, 100);
      }
    },

    closeTooltip() {
      this.isShowTooltip = false;
      clearTimeout(this.tooltipTimeout);
    },
  },
};
</script>

<template>
  <div class="analytics-chart-legend-item">
    <div class="analytics-chart-legend-item__title">
      <span :style="{ backgroundColor: item.color }" />
      <p>{{ item.name }}</p>
      <div
        v-if="item.tooltip"
        v-tooltip="{
          content: item.tooltip,
          show: isShowTooltip,
          trigger: 'manual',
          placement: 'auto',
          offset: 4,
        }"
      >
        <span
          class="icon-info"
          @mouseenter="showTooltip(false)"
          @mouseleave="closeTooltip"
          @click="showTooltip(true)"
        />
      </div>
    </div>

    <div class="analytics-chart-legend-item__value">
      <span class="icon-analytics-pay" />
      <p>{{ item.value }} RC</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.analytics-chart-legend-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    > span {
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }

    > p {
      @include text-2;
      color: $dark-primary;
    }

    .icon-info {
      width: 16px;
      height: 16px;
      background-color: $dark-sixth;
      cursor: pointer;

      &:hover {
        background-color: $dark-primary;
      }
    }
  }

  &__value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    > span {
      width: 20px;
      height: 20px;
      background-color: $dark-fifth;
    }

    > p {
      @include text-2;
      color: $dark-fifth;
    }
  }
}
</style>
