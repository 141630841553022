<script>
import { mapActions, mapState } from "vuex";
import { ANALYTICS_CARDS } from "@/package/const/analytics-cards";
import { format } from "date-fns";
import { debounce } from "throttle-debounce";
import AnalyticsCard from "@/components/analytics/AnalyticsCard.vue";
import MainSkeletonBox from "@/components/helpers/MainSkeletonBox.vue";
import MainDateRange from "@/components/helpers/MainDateRange.vue";
import MainButtonIcon from "@/components/helpers/MainButtonIcon.vue";
import AnalyticsPieChart from "@/components/analytics/AnalyticsPieChart.vue";
import AnalyticsChartLegend from "@/components/analytics/AnalyticsChartLegend.vue";
import AnalyticsLineChart from "@/components/analytics/AnalyticsLineChart.vue";

export default {
  components: {
    AnalyticsLineChart,
    AnalyticsChartLegend,
    AnalyticsPieChart,
    MainButtonIcon,
    MainDateRange,
    MainSkeletonBox,
    AnalyticsCard,
  },

  data() {
    return {
      ANALYTICS_CARDS,

      isScrollEnd: false,
      cardScroll: 0,

      selectedCategory: null,
      date: null,
    };
  },

  watch: {
    innerWidth() {
      this.setCardScroll();
    },

    date: {
      deep: true,
      handler() {
        if (
          this.date === null ||
          (this.date?.start === "" && this.date?.end === "")
        ) {
          this.loadAnalytics();
        } else if (
          format(new Date(this.date.start), "yyyy-MM-dd") !==
            this.analyticsGraphs.total.xAxis.data[0] ||
          format(new Date(this.date.end), "yyyy-MM-dd") !==
            this.analyticsGraphs.total.xAxis.data[
              this.analyticsGraphs.total.xAxis.data.length - 1
            ]
        ) {
          this.loadAnalytics({
            start: format(new Date(this.date.start), "yyyy-MM-dd"),
            end: format(new Date(this.date.end), "yyyy-MM-dd"),
          });
        }
      },
    },
  },

  computed: {
    ...mapState({
      innerWidth: (state) => state.innerWidth,
      analyticsTypes: (state) => state.analytics.analyticsTypes,
      analyticsGraphs: (state) => state.analytics.analyticsGraphs,
      statusAnalyticsTypes: (state) => state.analytics.statusAnalyticsTypes,
      statusAnalyticsGraphs: (state) => state.analytics.statusAnalyticsGraphs,
    }),

    isVisibleScrollButton() {
      return this.innerWidth <= 1310;
    },

    isVisibleGraphs() {
      return (
        this.statusAnalyticsTypes === "LOADING" ||
        (this.statusAnalyticsTypes === "SUCCESS" &&
          this.analyticsTypes?.[this.selectedCategory?.id] > 0)
      );
    },
  },

  methods: {
    ...mapActions({
      loadAnalyticsTypes: "analytics/loadAnalyticsTypes",
      loadAnalyticsGraphs: "analytics/loadAnalyticsGraphs",
    }),

    loadAnalytics: debounce(300, function (params) {
      this.loadAnalyticsTypes(params);
      this.loadAnalyticsGraphs(params).then((response) => {
        if (
          (this.date === null ||
            (this.date?.start === "" && this.date?.end === "")) &&
          response?.data?.total?.xAxis?.data
        ) {
          this.date = {
            start: response.data?.total?.xAxis?.data[0],
            end:
              response.data?.total?.xAxis?.data[
                response.data?.total?.xAxis?.data?.length - 1
              ],
          };
        }
      });
    }),

    scrollCardsList() {
      const el = document.getElementById("cardsList");

      el.scrollTo({
        left: this.isScrollEnd ? -1000 : 1000,
        behavior: "smooth",
      });
    },

    setCardScroll() {
      let el = document.getElementById("cardsList");

      this.cardScroll = el.scrollHeight - el.clientHeight;
    },

    selectOperationsCategory(id) {
      this.selectedCategory = this.ANALYTICS_CARDS.find(
        (category) => category.id === id
      );
    },
  },

  beforeMount() {
    this.selectedCategory = ANALYTICS_CARDS[0];
  },

  mounted() {
    this.loadAnalytics();

    const el = document.getElementById("cardsList");

    el.addEventListener("scrollend", () => {
      this.isScrollEnd = el.scrollLeft > 0;
    });
  },
};
</script>

<template>
  <div class="analytics-view default-content-padding">
    <div class="analytics-view__wrapper">
      <div class="analytics-view__filters filters">
        <div class="filters__date-range">
          <p>Показать за период</p>

          <MainDateRange
            v-model="date"
            class="date-range"
            :background="'white'"
          />
        </div>
      </div>

      <div class="analytics-view__cards cards">
        <div id="cardsList" class="cards__list">
          <AnalyticsCard
            v-for="card in ANALYTICS_CARDS"
            :key="card.id"
            :card="card"
            :selected="card.id === selectedCategory.id"
            @select="selectOperationsCategory(card.id)"
          />
        </div>

        <MainButtonIcon
          v-if="isVisibleScrollButton"
          class="cards__button-arrow"
          :class="{ 'cards__button-arrow_left': isScrollEnd }"
          :type="'button'"
          @click="scrollCardsList"
        >
          <span class="icon-arrow-medium" />
        </MainButtonIcon>
      </div>

      <template v-if="isVisibleGraphs">
        <div class="analytics-view__line-graph-wrapper">
          <div
            v-if="statusAnalyticsGraphs === 'LOADING'"
            class="line-graph__skeleton-wrapper"
          >
            <MainSkeletonBox
              style="width: 100%; height: 242px; border-radius: 8px"
            />
          </div>

          <AnalyticsLineChart
            v-if="statusAnalyticsGraphs === 'SUCCESS'"
            :category="selectedCategory"
            :data="analyticsGraphs"
          />
        </div>

        <div class="analytics-view__chart-wrapper">
          <div class="analytics-view__chart-legend">
            <MainSkeletonBox
              v-if="statusAnalyticsGraphs === 'LOADING'"
              style="width: 100%; height: 220px; border-radius: 8px"
            />
            <AnalyticsChartLegend
              v-if="statusAnalyticsGraphs === 'SUCCESS'"
              :category="selectedCategory"
              :data="analyticsGraphs"
            />
          </div>

          <div class="analytics-view__chart">
            <MainSkeletonBox
              v-if="statusAnalyticsGraphs === 'LOADING'"
              style="width: 100%; height: 220px; border-radius: 8px"
            />

            <AnalyticsPieChart
              v-if="statusAnalyticsGraphs === 'SUCCESS'"
              :category="selectedCategory"
              :data="analyticsGraphs"
            />
          </div>
        </div>
      </template>

      <template v-else>
        <div class="analytics-view__no-data no-data">
          <div class="no-data__icon">
            <span class="icon-statistic"></span>
          </div>

          <p>Доход за данный период отсутствует</p>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.analytics-view {
  &__wrapper {
    max-width: 940px;
    padding-bottom: 78px;
  }

  .filters {
    margin-bottom: 24px;

    &__date-range {
      > p {
        @include text-2;
        color: $dark-primary;
        margin-bottom: 8px;
      }
    }

    .date-range {
      &::v-deep {
        .main-date-range__input-wrapper {
          flex-wrap: wrap;
        }

        .main-date-range__button {
          width: 100%;
          margin-left: 0;
          margin-top: 8px;
        }
      }
    }
  }

  .cards {
    margin-bottom: 24px;
    position: relative;

    &__list {
      overflow: auto;
      display: grid;
      grid-template-columns: repeat(6, minmax(140px, 150px));
      column-gap: 8px;
    }

    &__button-arrow {
      width: 32px;
      height: 32px;
      background-color: $light-primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -16px;
      top: calc(50% - 16px);

      .icon-arrow-medium {
        width: 24px;
        height: 24px;
        background-color: $dark-sixth;
      }

      &_left {
        left: -16px;

        .icon-arrow-medium {
          transform: rotate(180deg);
        }
      }
    }
  }

  .no-data {
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__icon {
      width: 96px;
      height: 96px;
      background-color: $gold-main;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      .icon-statistic {
        width: 48px;
        height: 48px;
        background-color: $dark-primary;
      }
    }

    > p {
      @include body-1;
      color: $dark-primary;
    }
  }

  &__line-graph-wrapper,
  &__chart-wrapper {
    border-radius: 12px;
    background-color: $light-primary;
  }

  .line-graph {
    &__skeleton-wrapper {
      padding: 32px;
    }
  }

  &__chart-wrapper {
    display: grid;
    grid-template-columns: 70% 1fr;
    column-gap: 32px;
    margin-top: 24px;
    padding: 32px;
  }
}

@media (max-width: 1440px) {
  .analytics-view {
    &__chart-wrapper {
      grid-template-columns: 1fr;
      row-gap: 32px;
    }
  }
}

@media (max-width: 1280px) {
  .analytics-view {
    &__line-graph-wrapper,
    &__chart-wrapper {
      overflow: auto;
    }
  }
}

@media (max-width: 1024px) {
  .analytics-view {
    &__chart-wrapper {
      padding: 24px;
    }

    .line-graph {
      &__skeleton-wrapper {
        padding: 24px;
      }
    }
  }
}
</style>
