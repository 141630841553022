export default function setAnalyticsLegendTooltip(name) {
  switch (name) {
    case "Продажа тарифов":
      return "Личная продажа Клиент Смарт, Клиент Стандарт и структурный бонус";

    case "Продажа тарифов ТСП":
      return "Личные продажи и по структуре";

    case "Вознаграждение за покупки в ТСП":
      return "Бонусы личные и клиентов";

    case "Онлайн-покупки":
      return "Бонусы личные и клиентов";

    default:
      return;
  }
}
